import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const PageTitleMain = styled.div`
  overflow: hidden;

  ${mq.belowHorizontal} {
    padding-top: 11.1rem;
    padding-bottom: 2rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(125)};
    padding-right: ${horizontalScale(65)};
  }
`

export const PageTitleText = styled.h1`
  position: relative;
  max-width: 1.26em;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 22.3rem;
  line-height: 0.84;
  text-transform: uppercase;
  word-break: break-all;

  ${mq.mobileL} {
    max-width: none;
  }

  ${mq.horizontal} {
    transform: translateY(${horizontalScale(166)});
    max-width: 1.22em;
    font-size: ${horizontalScale(638)};
    letter-spacing: -0.01em;
  }
`

export const PageTitleLabel = styled.span`
  position: absolute;
  top: 0;
  left: ${horizontalScale(-38)};
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
  display: none;
  font-family: ${font.primary};
  font-size: ${horizontalScale(12)};
  font-weight: ${font.weight.regular};
  line-height: 1;
  letter-spacing: 0;
  word-break: normal;

  ${mq.horizontal} {
    display: block;
  }
`
