import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const PageIntroMain = styled.div`
  padding-top: 0;
  padding-bottom: 5.2rem;
  ${horizontalPadding()}

  ${mq.horizontal} {
    height: ${horizontalScale(882)};
    padding-left: 0;
    padding-right: ${horizontalScale(146)};
  }
`

export const PageIntroContent = styled.div`
  > * + * {
    margin-top: 1em;
  }

  p {
    font-size: 3.2rem;
    font-weight: ${font.weight.light};
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${mq.horizontal} {
      font-size: ${horizontalScale(32)};
    }
  }
`

export const TicketLink = styled.a`
  margin-top: 1em;
  display: inline-flex;
  padding: 1.8rem 2.4rem 1.7rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 2.4rem;
  line-height: 1.2;
  text-transform: uppercase;
  border: 0.1rem solid ${colors.dark};

  ${mq.horizontal} {
    margin-top: ${horizontalScale(32)};
    padding-top: ${horizontalScale(18)};
    padding-right: ${horizontalScale(24)};
    padding-left: ${horizontalScale(24)};
    padding-bottom: ${horizontalScale(17)};
    font-size: ${horizontalScale(24)};
  }
`

export const PageIntroFooter = styled.div`
  margin-top: 4.2rem;

  ${mq.horizontal} {
    margin-top: ${horizontalScale(154)};
  }
`

export const PageIntroFooterTitle = styled.p`
  margin-bottom: 1.1rem;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.medium};
  font-size: 2.8rem;
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(28)};
  }
`
