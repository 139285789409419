import React from 'react'
import PropTypes from 'prop-types'
import RichText from '../RichText'
import LinkBlock from '../LinkBlock'
import IconInstagram from '../_svgs/IconInstagram'
import IconMail from '../_svgs/IconMail'
import {
  PageIntroMain,
  PageIntroContent,
  TicketLink,
  PageIntroFooter,
  PageIntroFooterTitle,
} from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { animation } from '../../styles/vars/animation.style'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import Routes from '../../routes'
import SlideMask from '../SlideMask'

const PageIntro = ({
  text,
  showContactFooter,
  showTicketLink,
  introCtaText,
  introCtaLink,
}) => {
  const entryDelay = animation.pageExitDuration

  return (
    <PageIntroMain>
      <PageIntroContent>
        <RichText content={text} delay={entryDelay} />
      </PageIntroContent>

      {showTicketLink && (
        <AnimateSlideIn delay={entryDelay}>
          <TicketLink href={introCtaLink} target="_blank">
            <SlideMask>{introCtaText}</SlideMask>
          </TicketLink>
        </AnimateSlideIn>
      )}

      {showContactFooter && (
        <PageIntroFooter>
          <PageIntroFooterTitle>
            <AnimateSplitText delay={entryDelay}>
              For more information or questions reach out
            </AnimateSplitText>
          </PageIntroFooterTitle>

          <AnimateSlideIn delay={entryDelay}>
            <LinkBlock
              url={Routes.instagram}
              icon={<IconInstagram />}
              text="Message us"
            />
          </AnimateSlideIn>

          <AnimateSlideIn delay={entryDelay}>
            <LinkBlock
              url={`mailto:${Routes.infoEmail}`}
              icon={<IconMail />}
              text={Routes.infoEmail}
            />
          </AnimateSlideIn>
        </PageIntroFooter>
      )}
    </PageIntroMain>
  )
}

PageIntro.propTypes = {
  text: PropTypes.object.isRequired,
  showContactFooter: PropTypes.bool,
  showTicketLink: PropTypes.bool,
  introCtaText: PropTypes.string,
  introCtaLink: PropTypes.string,
}

PageIntro.defaultProps = {
  showContactFooter: true,
  showTicketLink: false,
}

export default PageIntro
