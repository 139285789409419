import React from 'react'
import { graphql } from 'gatsby'
import { ScrollWrapper } from '../components/Scroll/index.style'
import SEO from '../components/SEO'
import { colors } from '../styles/vars/colors.style'
import ScrollSection from '../components/ScrollSection'
import PageTitle from '../components/PageTitle'
import PageIntro from '../components/PageIntro'
import PageLinks from '../components/PageLinks'

const IndexPage = ({ data }) => {
  const {
    title,
    seoDescription,
    introText,
    introCtaText,
    introCtaLink,
    events,
  } = data.allContentfulPageEvents.nodes[0]
  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />

      <ScrollWrapper color={colors.events}>
        <ScrollSection width={920}>
          <PageTitle title="Events" />
        </ScrollSection>

        <ScrollSection width={540}>
          <PageIntro
            text={introText}
            showTicketLink={true}
            introCtaText={introCtaText}
            introCtaLink={introCtaLink}
          />
        </ScrollSection>

        <ScrollSection width={1340}>
          <PageLinks
            type="events"
            links={events}
            labelA="What’s on"
            labelB="at The LAAB"
          />
        </ScrollSection>
      </ScrollWrapper>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query EventsPageQuery {
    allContentfulPageEvents {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        introText {
          raw
        }
        introCtaText
        introCtaLink
        events {
          slug
          name
          dateText
        }
      }
    }
  }
`
