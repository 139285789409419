import React from 'react'
import PropTypes from 'prop-types'
import { PageTitleLabel, PageTitleMain, PageTitleText } from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { animation } from '../../styles/vars/animation.style'

const PageTitle = ({ title }) => {
  const entryDelay = animation.pageExitDuration

  return (
    <PageTitleMain>
      <PageTitleText>
        <PageTitleLabel aria-hidden>
          <AnimateSlideIn delay={entryDelay + 0.2} direction="right">
            {title}
          </AnimateSlideIn>
        </PageTitleLabel>
        <AnimateSplitText delay={entryDelay} heading>
          {title}
        </AnimateSplitText>
      </PageTitleText>
    </PageTitleMain>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle
